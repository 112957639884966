
import { defineComponent } from "vue";
// import OwnedBuildingPolicyInformation from "@/views/reusable/policies/policy-information/house-insurance/OwnedBuildingPolicyInformation.vue";
import OwnedBuildingPolicyInformation from "@/views/reusable/policies/policy-information/house-insurance/BuildingPolicyInformation.vue";

export default defineComponent({
  name: "broker-client-rented-building-policy-information",
  components: {
    OwnedBuildingPolicyInformation,
  },
  created() {
    //Set page title
    document.title =
      "Owned Building Policy Information | " + process.env.VUE_APP_TITLE;
  },
});
